/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
/* eslint-disable @typescript-eslint/no-unused-vars */

import BlockIcon from '@mui/icons-material/Block'
import { Box, Typography, useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { currentPortal } from 'components/materialTheme/commonTheme'

const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: '#fdfdfd',
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid #3c3c3c',
        borderRadius: '2px',
        padding: '2px 8px',
        '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementBottom': {
                marginTop: '2px'
            },
            '&.MuiTooltip-tooltipPlacementTop': {
                marginBottom: '2px'
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
                marginRight: '2px'
            },
            '&.MuiTooltip-tooltipPlacementRight': {
                marginLeft: '2px'
            }
        }
    },
    div: { '&> *': { height: '100%' } }
}))

export default function TooltipComponent({ title, children }: { title: string; children: React.ReactNode }) {
    const classes = useStyles()
    const firstChild = React.Children.toArray(children)[0]
    const disabled = React.isValidElement(firstChild) ? firstChild.props.disabled : false
    const theme = useTheme()

    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
                <Box display="flex" alignItems="center">
                    <Typography component="div" variant="caption">
                        {title}
                    </Typography>
                    {disabled && currentPortal() === 'drg' && (
                        <React.Fragment>
                            &nbsp;
                            <BlockIcon
                                style={{
                                    color: theme.palette.error.main,
                                    fontSize: theme.typography.caption.fontSize
                                }}
                            />
                        </React.Fragment>
                    )}
                </Box>
            }
            placement={'bottom-start'}
            enterDelay={500}
        >
            <div className={classes.div}>{children}</div>
        </Tooltip>
    )
}
